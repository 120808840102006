<template>
  <div class="af-card">
    <div v-if="loading">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
    </div>
    <div v-else>
      <div v-if="successfulConfirmation || isAlreadyConfirmed">
        <img
          class="mx-auto is-block mb-3"
          :src="require('@/assets/images/email-confirmation-success.svg')"
          alt="email-confirmation-success"
        />

        <p class="is-size-3 has-text-weight-bold has-text-centered mb-5">
          <label v-if="successfulConfirmation">
            {{ $t("af:email_confirmation.successful.title") }}
          </label>

          <label v-else>
            {{ $t("af:email_confirmation.already_confirmed.title") }}
          </label>
        </p>

        <p class="mb-6" v-if="loggedIn">
          {{ $t("af:email_confirmation.successful.subtitle") }}
        </p>

        <p v-if="loggedIn" class="has-text-weight-bold has-text-centered mb-4">
          <a
            @click="backToTheApplication"
            id="emailConfirmationBackToApplication"
          >
            {{ $t("af:email_confirmation.button.back_to_page") }}</a
          >
        </p>
      </div>
      <div v-else>
        <img
          class="mx-auto is-block mb-3"
          :src="require('@/assets/images/email-confirmation-failed.svg')"
          alt="email-confirmation-failed"
        />

        <p class="is-size-3 has-text-weight-bold has-text-centered mb-5">
          {{ $t("af:email_confirmation.unsuccessful.title") }}
        </p>

        <div v-if="loggedIn">
          <div v-if="!newCodeSent">
            <p class="mb-5">
              {{ $t("af:email_confirmation.unsuccessful.logged_in.subtitle") }}
            </p>
            <b-button
              type="is-blue"
              @click="sendNewCode"
              expanded
              id="emailConfirmationSendNewCode"
              >{{ $t("af:email_confirmation.button.send_new_code") }}</b-button
            >
          </div>

          <div v-else>
            <p class="mb-5">
              <span
                v-html="
                  $t('af:email_confirmation.new_code_sent_title', {
                    email: currentEmail,
                  })
                "
              ></span>
            </p>
          </div>
        </div>

        <div v-else>
          <p class="mb-5">
            {{
              $t("af:email_confirmation.unsuccessful.not_logged_in.subtitle")
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateNonce } from "@/utils/util";
import { APPLICATION_MODE } from "@/utils/const";

export default {
  name: "EmailConfirmation",
  title: "af:page.email_confirmation.title",
  data() {
    return {
      successfulConfirmation: false,
      isAlreadyConfirmed: false,
      newCodeSent: false,
      currentEmail: "",
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
      currentApplicationMode: "profile/getCurrentApplicationMode",
    }),
    url() {
      if (this.loggedIn) {
        return this.currentApplicationMode === APPLICATION_MODE.MORTGAGE
          ? this.$store.getters["profile/getMortgageBackPath"]
          : this.$store.getters["profile/getBackPath"];
      }
      return "/login";
    },
  },
  async created() {
    let id = this.$route.query.id;
    let code = this.$route.query.code;
    let nonce = this.$route.query.nonce;

    if (!id || !code || !nonce) {
      if (this.loggedIn) {
        await this.$router.push("/profile");
      } else {
        await this.$router.push("/login");
      }
    } else {
      if (this.loggedIn) {
        let isEmailConfirmedResponse = await this.$store.dispatch(
          "profile/isEmailConfirmed"
        );
        this.isAlreadyConfirmed = isEmailConfirmedResponse.isEmailConfirmed;
        this.currentEmail = isEmailConfirmedResponse.currentEmail;
      }

      if (!this.isAlreadyConfirmed) {
        try {
          await this.$store.dispatch("session/confirmEmail", {
            id: id,
            code: code,
            nonce: nonce,
          });
          this.successfulConfirmation = true;
        } catch (ex) {
          console.error(ex);
        }
      }

      if (
        (this.successfulConfirmation || this.isAlreadyConfirmed) &&
        this.loggedIn
      ) {
        setTimeout(() => {
          this.backToTheApplication();
        }, 5000);
      }

      this.loading = false;
    }
  },
  methods: {
    async backToTheApplication() {
      await this.$store.dispatch("profile/fetch");

      let emailConfirmationDataResponse = await this.$store.dispatch(
        "profile/getEmailConfirmationData"
      );

      if (this.url === "/offers") {
        await this.$router.push({
          name: "Offers",
          params: { offerIdToOpen: emailConfirmationDataResponse.offerId },
        });
      } else if (this.url === "/mortgage/offers") {
        await this.$router.push({
          name: "MortgageOffers",
          params: { offerIdToOpen: emailConfirmationDataResponse.offerId },
        });
      } else if (this.url === "/mortgage/invitation-offer") {
        await this.$router.push({
          name: "MortgageInvitationOffer",
          params: { openOffer: "invited" },
        });
      } else {
        await this.$router.push(this.url);
      }
    },
    async sendNewCode() {
      await this.$store.dispatch(
        "profile/sendConfirmationEmail",
        generateNonce()
      );
      this.newCodeSent = true;
    },
  },
};
</script>

<style scoped lang="scss">
.af-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 40px 80px;
  }
}
</style>
